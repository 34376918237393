import React, { useEffect, useState } from 'react'
import { PageProps } from 'gatsby'
import queryString from 'query-string'

import Layout from 'layout/base'
import Seo from 'components/seo'

import { SingleTemplate } from 'templates/singleTemplate'
import globalTitle from 'utils/globalTitle'

interface NewsQuery {
  wpPost: {
    title: { rendered: string }
    content: { rendered: string }
  }
}

const PreviewPage: React.FC<PageProps<NewsQuery>> = ({ location }) => {
  const { id } = queryString.parse(location.search)
  const [data, setData] = useState<NewsQuery | null>(null)
  const wpJwtTOKEN = process.env.GATSBY_WP_JWT_TOKEN
  const wpEndpoint = process.env.GATSBY_WP_ENDPOINT

  useEffect(() => {
    if (!wpJwtTOKEN) {
      return
    }

    fetch(`${wpEndpoint}?rest_route=/wp/v2/posts/${id}&_embed&status=draft`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${wpJwtTOKEN}`,
      },
    })
      .then(res => res.json())
      .then(res => {
        setData({ wpPost: res })
      })
      .catch(error => {
        console.error('通信に失敗しました', error)
      })
  }, [id])

  return (
    <Layout pageType="page" ctaType="type1">
      <Seo title={`プレビュー${globalTitle(location.pathname)}`} path={location.pathname} isNoIndex={true} />
      <SingleTemplate
        headingText="プレビュー"
        titleText={data?.wpPost?.title?.rendered || 'プレビュー投稿が存在しません'}
        timeText="YYYY/MM/DD"
        contentText={data?.wpPost?.content?.rendered || 'プレビュー投稿のIDが正しいかご確認ください。もしくは投稿のコンテンツがありません。'}
        prevNews={undefined}
        nextNews={undefined}
      />
    </Layout>
  )
}

export default PreviewPage
